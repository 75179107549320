<template>
  <div class="company-list-root-container">
    <div class="page-title">全部企业用户</div>
    <Input
      prefix="ios-search"
      v-model="search_content"
      @keyup.enter.native="getList"
      placeholder="搜索"
      style="width: 250px; margin-top: 20px"
    />
    <div class="company-list-main-container">
      <company-item
        style="margin: 10px"
        v-for="item in companyList"
        :key="item.company_id"
        :data-source="item"
        @click.native="gotoDetail(item)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import moment from "moment";
import CompanyItem from "./Item/CompanyItem";
export default {
  components: {
    "company-item": CompanyItem,
  },
  data() {
    return {
      icons: icon,
      companyList: [],
      search_content: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions({
      companyGetCompanyListAction: "companyGetCompanyList",
    }),
    getList() {
      this.companyGetCompanyListAction({
        search_content: this.search_content,
      })
        .then((res) => {
          this.companyList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    gotoDetail(item) {
      this.$router.push({
        name: "company-detail",
        params: {
          id: item.company_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.company-list-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f7f7f7;
  padding: 50px 200px 50px 200px;
  overflow: hidden;
}
.company-list-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>